import React from "react";
import { toWei } from "web3-utils";
import { Box, Typography } from "@mui/material";
import { PaymentIntent, User, Reward } from "@livepix/sdk-js/types/core";
import { ProfileData, TipAction } from "@livepix/sdk-js/types/profile";
import useFingerprint from "@livepix/components/hooks/useFingerprint";
import useWebservice from "@livepix/components/hooks/useWebservice";
import useCheckout from "@livepix/components/hooks/useCheckout";
import { ReCAPTCHA } from "@livepix/components/common/Captcha";
import useAlerts from "@livepix/components/hooks/useAlerts";
import ProfileButton from "@livepix/components/profile/ProfileButton";
import TransactionForm from "components/profile/TransactionForm";
import ProfileCampaign from "./ProfileCampaign";
import PaymentReceipt from "./PaymentReceipt";
import SetupRewards from "../reward/SetupRewards";
import SetupVoice from "./SetupVoice";
import Loading from "./Loading";

type Props = {
  user?: User;
  profile: ProfileData;
  rewards: Reward[];
  captcha?: ReCAPTCHA;
  suggestedAmount?: number;
  onResize: () => void;
};

export default function TransactionSlide({ user, profile, rewards, captcha, suggestedAmount, onResize }: Props) {
  const alerts = useAlerts();
  const fingerprint = useFingerprint();
  const checkout = useCheckout();
  const webservice = useWebservice();

  const [step, setStep] = React.useState<"form" | "voice" | "rewards" | "receipt" | "loading" | "error">("form");

  const [payment, setPayment] = React.useState<PaymentIntent>();
  const [proof, setProof] = React.useState<string>();

  const [username, setUsername] = React.useState<string>();
  const [message, setMessage] = React.useState<string>();
  const [audio, setAudio] = React.useState<string>();
  const [amount, setAmount] = React.useState<number>();
  const [currency, setCurrency] = React.useState<string>("BRL");
  const [voice, setVoice] = React.useState<string>("default");
  const [music, setMusic] = React.useState<boolean>(false);

  const [errorMessage, setErrorMessage] = React.useState<string>();

  React.useEffect(() => onResize(), [step]);

  const tip = async () => {
    if (!captcha) {
      alerts.error("Não foi possível enviar sua mensagem");
      return;
    }

    setStep("loading");

    try {
      const requester = await fingerprint.getVisitor();
      const captchaToken = await captcha.executeAsync();

      let product = "message";

      if (voice !== "default") {
        product = "ai-voice-message";
      } else if (audio) {
        product = "audio-message";
      } else if (music) {
        product = "ai-music";
      }

      const data = {
        amount: currency === "BRL" ? Math.round(amount! * 100) : toWei(amount!.toString()),
        requester,
        currency,
        username,
        message,
        audio,
        voice,
        product,
        captchaToken,
      };

      const response = await webservice.post<{
        paymentId: string;
      }>(`/profile/${profile.username}/tip`, data, {
        timeout: 30_000,
      });

      checkout.open(
        response.data.paymentId,
        (payment, proof) => {
          setPayment(payment);
          setProof(proof);
          setStep("receipt");
        },
        () => {
          setStep("form");
          setVoice("default");
        },
      );

      setAudio(undefined);
      setVoice("default");
    } catch (e: any) {
      setErrorMessage(
        e.response?.data?.message || "Não foi possível enviar sua mensagem. Tente novamente em alguns segundos.",
      );
      setStep("error");
      setVoice("default");
    } finally {
      captcha.reset();
    }
  };

  const selectVoice: TipAction = async (
    currency: string,
    amount: number,
    username?: string,
    message?: string,
    audio?: string,
    useCustomVoice?: boolean,
    useAiMusic?: boolean,
  ) => {
    setCurrency(currency);
    setUsername(username);
    setMessage(!audio ? message : undefined);
    setAudio(audio);
    setAmount(amount);
    setMusic(useAiMusic || false);

    if (audio || useAiMusic || !useCustomVoice) {
      setStep("rewards");
    } else {
      setStep("voice");
    }
  };

  const reset = () => {
    setStep("form");
    setVoice("default");
    setAudio(undefined);
    setAmount(undefined);
    setCurrency("BRL");
    setPayment(undefined);
    setProof(undefined);
    setErrorMessage(undefined);
  };

  return (
    <>
      {step === "loading" && <Loading />}
      {step === "form" && (
        <>
          {profile.campaign && <ProfileCampaign username={profile.username} campaign={profile.campaign} />}
          <TransactionForm
            user={user}
            profile={profile}
            color={profile.color}
            username={username}
            message={message}
            amount={amount}
            currency={currency}
            captcha={captcha}
            currencies={profile.currencies}
            suggestedAmount={suggestedAmount}
            messageEnabled={profile.products.message.enabled}
            minAmount={profile.products.message.minimumAmount}
            maxMessageLength={profile.products.message.maximumCharacters}
            customVoiceEnabled={profile.products["ai-voice-message"].enabled}
            minCustomVoiceAmount={profile.products["ai-voice-message"].minimumAmount}
            maxCustomVoiceMessageLength={profile.products["ai-voice-message"].maximumCharacters}
            audioEnabled={profile.products["audio-message"].enabled}
            minAudioAmount={profile.products["audio-message"].minimumAmount}
            maxAudioDuration={profile.products["audio-message"].maximumDuration}
            musicEnabled={profile.products["ai-music"].enabled}
            minMusicAmount={profile.products["ai-music"].minimumAmount}
            maxMusicDuration={profile.products["ai-music"].maximumDuration}
            onTip={selectVoice}
            onResize={onResize}
          />
        </>
      )}
      {step === "voice" && (
        <SetupVoice
          profile={profile}
          onContinue={(voice) => {
            setVoice(voice);
            setStep("rewards");
          }}
          onResize={onResize}
        />
      )}
      {step === "rewards" && (
        <SetupRewards
          user={user}
          color={profile.color}
          amount={amount || 0}
          rewards={rewards}
          trigger="transaction"
          onContinue={() => tip()}
          onResize={onResize}
        />
      )}
      {step === "receipt" && (
        <PaymentReceipt
          profile={profile}
          payment={payment!}
          proof={proof!}
          onResize={onResize}
          onReset={() => reset()}
        />
      )}
      {step === "error" && (
        <Box marginTop="20px">
          <Typography variant="h5" fontWeight="600">
            Ooops!
          </Typography>
          <Box margin="10px 0 40px">
            <Typography fontSize={20}>{errorMessage}</Typography>
          </Box>
          <ProfileButton customcolor={profile.color} onClick={() => reset()}>
            Tentar novamente
          </ProfileButton>
        </Box>
      )}
    </>
  );
}
